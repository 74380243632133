import { Fragment, memo, useEffect, useState } from 'react'
import { getActivePet, getAvailablePets } from 'store/selectors'
import styled, { keyframes } from 'styled-components'

import { Event } from 'types'
import TimelineDivider from './timelineDivider'
import TimelineItem from './timelineItem'
import UnderConstruction from './underConstruction'
import { getIsEventsFetched } from 'entity/event'
import { getPetListDirection } from 'utils'
import { useSelector } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'

const animAppear = (direction) => keyframes`
  from {
    opacity: 0;
    transform: translateX(${Math.sign(direction) * 100}%)
  }
  to {
    opacity: 1;
    transform: none;
  }`

const ItemWrapper = styled.div<{ direction: number }>`
  animation: ${(props) => animAppear(props.direction)} 0.2s cubic-bezier(0.08, 0.72, 0.65, 0.95)
    both;
`

type TimelineTypes = {
  events: Event[]
  showActual?: boolean
  onFirstEventInView?: () => void
  onLastEventInView?: () => void
}

const Timeline = memo((props: TimelineTypes) => {
  const { events, showActual = true } = props
  const { t } = useTranslation('common')

  const isEventsFetched = useSelector(getIsEventsFetched)
  const pets = useSelector(getAvailablePets)
  const activePet = useSelector(getActivePet)

  const [previousPetId, setPreviousPetId] = useState<string>(activePet)
  const [currentPetId, setCurrentPetId] = useState<string>(activePet)

  const [currentEvents, setCurrentEvents] = useState<Event[]>([])

  useEffect(() => {
    setPreviousPetId(currentPetId)
    setCurrentPetId(activePet)
    setCurrentEvents([])
  }, [activePet])

  useEffect(() => {
    setCurrentEvents(events)
  }, [events])

  const getDirection = () => {
    return getPetListDirection({ pets, previousPetId, currentPetId })
  }

  // helper constants
  let dividerSet = false
  const today = new Date()

  if (!isEventsFetched) {
    return null
  }

  if (events.length === 0) {
    return <UnderConstruction textCode="EMPTY_TIMELINE" />
  }

  const onIsEventInViewChange = (options: { isVisible: boolean; index: number }) => {
    if (props.onFirstEventInView && options.isVisible && options.index === 0) {
      props.onFirstEventInView()
    } else if (
      props.onLastEventInView &&
      options.isVisible &&
      options.index === props.events.length - 1
    ) {
      props.onLastEventInView()
    }
  }

  return (
    <>
      {currentEvents.map((event, i) => {
        const item = (
          <ItemWrapper key={event.id + currentPetId} direction={getDirection()}>
            <TimelineItem
              event={event}
              showPetName={currentPetId === 'all'}
              onIsInViewChange={(isVisible) => onIsEventInViewChange({ isVisible, index: i })}
            />
          </ItemWrapper>
        )
        if (!showActual) return item

        const eventDate = new Date(event?.timelineAt)
        const isBeforeToday = eventDate < today
        if (!dividerSet && isBeforeToday) {
          dividerSet = true
          return (
            <Fragment key="divider-fragment">
              <ItemWrapper key={`${currentPetId}_divider`} direction={getDirection()}>
                <TimelineDivider key="divider" data={{ content: t('CURRENTLY') }} />
              </ItemWrapper>
              {item}
            </Fragment>
          )
        } else {
          return item
        }
      })}
    </>
  )
})

export default Timeline
