import { useEffect, useState } from 'react'

const useScroll = (elementId) => {
  const [scroll, setScroll] = useState({
    y: 0,
    direction: '',
  })

  const listener = (el) => {
    const newElement = document.getElementById(elementId)

    const elementHeight = newElement.scrollHeight
    setScroll((prev) => ({
      y: -el.scrollTop,
      direction:
        el.scrollTop === 0 || el.scrollTop === elementHeight
          ? null
          : prev.y > -el.scrollTop
          ? 'up'
          : 'down',
    }))
  }

  useEffect(() => {
    const el = document.getElementById(elementId)
    if (el) {
      el.addEventListener('scroll', () => listener(el), { passive: true })
      return () => el.removeEventListener('scroll', listener)
    }
  }, [])

  return scroll
}

export default useScroll
