import { togglePetHeader } from 'store/actions'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import useScroll from './useScroll'

const useTogglePetHeaderOnScroll = () => {
  const { direction } = useScroll('timelineBox')
  const dispatch = useDispatch()

  useEffect(() => {
    if (direction === 'up') {
      dispatch(togglePetHeader(false))
    } else {
      dispatch(togglePetHeader(true))
    }
  }, [direction])
}

export default useTogglePetHeaderOnScroll
