import { DIRECTION_FUTURE, DIRECTION_PAST, fetchEvents, getEvents } from 'entity/event'
import { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SvgIcon from 'components/svgIcon'
import Timeline from 'components/timeline'
import { getActivePet } from 'store/selectors'
import styled from 'styled-components'
import useTogglePetHeaderOnScroll from 'hooks/useTogglePetHeaderOnScroll'
import useTranslation from 'next-translate/useTranslation'

const NoOverdueEvents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;

  svg {
    width: 40px;
    color: ${(props) => props.theme.colors.primary};
  }
`

type TimelineViewPropsTypes = {
  overdueFilter?: boolean
  showActual?: boolean
}

const TimelineView = memo((props: TimelineViewPropsTypes) => {
  const { t, lang } = useTranslation('common')
  const { overdueFilter = false, showActual = true } = props
  const dispatch = useDispatch()
  const events = useSelector(getEvents)
  const activePet = useSelector(getActivePet)

  useTogglePetHeaderOnScroll()

  const fetchEventsDefault = {
    id: undefined,
    petId: activePet === 'all' ? undefined : activePet,
    overdueFilter,
    addToExisting: false,
    direction: undefined,
  }

  useEffect(() => {
    dispatch(fetchEvents(fetchEventsDefault))
  }, [activePet, overdueFilter, lang])

  const clickNext = (direction) => {
    const event = direction === DIRECTION_FUTURE ? events[0] : events[events.length - 1]
    if (!event) return
    dispatch(
      fetchEvents({
        ...fetchEventsDefault,
        direction,
        addToExisting: true,
        fromId: event.id,
      })
    )
  }

  return overdueFilter && events?.length === 0 ? (
    <NoOverdueEvents>
      <SvgIcon code="icon-clock" /> {t('OVERDUE_TASKS_EMPTY')}
    </NoOverdueEvents>
  ) : (
    <Timeline
      events={events}
      showActual={showActual}
      onFirstEventInView={() => clickNext(DIRECTION_FUTURE)}
      onLastEventInView={() => clickNext(DIRECTION_PAST)}
    />
  )
})
export default TimelineView
