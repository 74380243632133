import MainLayout from 'layouts/mainLayout'
import { PetState } from 'apiLegacy'
import { TUTORIAL_IDS } from 'entity/tutorials'
import TimelineView from 'views/timelineView'
import { getActivePetState } from 'store/selectors'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

// TODO: define events type - waiting for api definition updates
const Home = () => {
  const activePetState = useSelector(getActivePetState)

  const handleEvent = (event) => {
    // eslint-disable-next-line no-console
    console.log(`Service worker event ${event.type} is triggered.`, event)
  }

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      // @ts-ignore
      'serviceWorker' in navigator &&
      window.workbox !== undefined
    ) {
      const wb = window.workbox
      // add event listeners to handle any of PWA lifecycle event
      // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-window.Workbox#events
      wb.addEventListener('installed', handleEvent)
      wb.addEventListener('controlling', handleEvent)
      wb.addEventListener('activated', handleEvent)

      // A common UX pattern for progressive web apps is to show a banner when a service worker has updated and waiting to install.
      // NOTE: MUST set skipWaiting to false in next.config.js pwa object
      // https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
      const promptNewVersionAvailable = (event) => {
        // eslint-disable-next-line no-console
        console.log('New version Available', event)
        // `event.wasWaitingBeforeRegister` will be false if this is the first time the updated service worker is waiting.
        // When `event.wasWaitingBeforeRegister` is true, a previously updated service worker is still waiting.
        // You may want to customize the UI prompt accordingly.
        // if (confirm('A newer version of this web app is available, reload to update?')) {
        //   wb.addEventListener('controlling', (event) => {
        //     window.location.reload()
        //   })

        // Send a message to the waiting service worker, instructing it to activate.
        //   wb.messageSW({ type: 'SKIP_WAITING' })
        // } else {
        //   console.log(
        //     'User rejected to reload the web app, keep using old verion. New verion will be automatically load when user open the app next time.'
        //   )
        // }
      }

      wb.addEventListener('waiting', promptNewVersionAvailable)
      wb.addEventListener('externalwaiting', promptNewVersionAvailable)

      // ISSUE - this is not working as expected, why?
      // I could only make message event listenser work when I manually add this listenser into sw.js file
      wb.addEventListener('message', handleEvent)

      /*
      wb.addEventListener('redundant', event => {
        console.log(`Event ${event.type} is triggered.`)
        console.log(event)
      })
      wb.addEventListener('externalinstalled', event => {
        console.log(`Event ${event.type} is triggered.`)
        console.log(event)
      })
      wb.addEventListener('externalactivated', event => {
        console.log(`Event ${event.type} is triggered.`)
        console.log(event)
      })
      */

      // never forget to call register as auto register is turned off in next.config.js
      wb.register()
    }
  }, [])

  return (
    <MainLayout
      petsRequired
      contentBoxId="timelineBox"
      petsEventFilter
      footer={{
        submitButton: {
          tutorialId: TUTORIAL_IDS.CREATE_NEW_EVENT,
          isDisabled: activePetState === PetState.Archived,
        },
        detail: {
          usePetMenu: true,
        },
      }}
    >
      <TimelineView />
    </MainLayout>
  )
}

export default Home
