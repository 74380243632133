import { styled } from 'styles/themes'

const Box = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 10px auto 1fr;
  align-items: center;
`
const Content = styled.div`
  background-color: ${(props) => props.theme?.colors?.primary || 'gray'};
  color: ${(props) => props.theme?.colors?.textInverted || 'lightgray'};
  border-radius: 10px;
  padding: 0px 10px;
  font-size: 10px;
`
const Hr = styled.div`
  background-color: ${(props) => props.theme?.colors?.primary || 'gray'};
  height: 3px;
`

const TimelineDivider = ({ data }) => {
  const { content } = data

  return (
    <Box>
      <Hr />
      <Content>{content}</Content>
      <Hr />
    </Box>
  )
}
export interface TimelineDividerProps {
  content: string
}
export default TimelineDivider
